body, html {
  font-family: 'Roboto', sans-serif; /* Updated to Roboto */
  font-size: 18px; /* Base font size increased */
  margin: 0;
  padding: 0;
  height: 100%;
  color: #191970; /* Updated font color to midnight blue */
  background: url('background.jpg') no-repeat center center fixed;
  background-size: cover;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 3rem auto; /* Increased margin */
  background-color: rgba(242, 222, 222, 0.38);
  border-radius: 8px;
  padding: 2rem; /* Increased padding */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header {
  background-color: #00796b;
  color: white;
  padding: 1rem 0;
  border-radius: 8px;
}

.nav-list {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
}

.nav-list li a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
}

.nav-list li a:hover {
  background-color: #005d4a;
}

.home, .about, .solutions, .team, .contact {
  padding: 2.5rem 0; /* Increased padding */
  text-align: center;
  margin: 2rem 0;
}

.home h1, .about h2, .solutions h2, .team h2, .contact h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.home p, .about p, .solutions p, .team p {
  font-size: 1.25rem;
}

.cta-button {
  padding: 10px 20px;
  background-color: #00796b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #005d4a;
}

.contact form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
  margin: auto;
}

.contact input, .contact textarea {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.footer {
  background-color: #004d40;
  color: white;
  padding: 1rem 0;
  text-align: center;
  border-radius: 8px;
}

.social-list {
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding-top: 1rem;
}

.social-list li a {
  color: white;
  text-decoration: none;
}

.contactDetails {
  text-align: left;
  font-size: smaller;
}